import React, { useEffect, useRef, useState } from 'react'
import AWS from 'aws-sdk';
import { useParams } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { jsPDF } from 'jspdf';
// import { useReactToPrint } from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify"
import {
    setWorkType,
    setAttributes,
    setStartDate,
    setEndDate,
    setSubcontractorName,
    setContractorName,
    setContractorEmail,
    setContractorPhoneNumber,
    setDescription,
    setEptw,
    setIdType,
    setMykadNumber,
    setGreenCardNumber,
    setLicenseNumber,
    setPassportNumber,
    setCompanyContactNumber,
    setSections,
    setDocumentNumber as eptwNum,
    setsubcontractorRepresentative,
    setForms,
    updateFillEptw,
    reset,
    setHirarcSections,
    getEptwById
} from '../../redux/features/eptw/eptwSlice';
import logo from "../../assets/img/FOX-Logo3.png"
import { Avatar } from '@mui/material';
import PrintPdf from './printPdf';
import classes from "../../styles/asset.module.css";
import { getUserProfile } from '../../redux/features/user/userSlice';
import { getCompanyProfile } from '../../redux/features/company/companySlice';
import { setDocumentNumber, setRevisionNumber, setRevisionDate, } from '../../redux/features/template/templateSclice';
import Signature from '../contractorView/Signature';
import Loader from '../../components/loader/loader';
import moment from 'moment';

AWS.config.update({
    region: 'ap-southeast-1',
    accessKeyId: 'AKIA3HYBXAPB2BK6VSEW',
    secretAccessKey: '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',
});

const s3 = new AWS.S3();

function uploadImageToS3(file) {
    const params = {
        Bucket: 'foxproject',
        Key: Date.now().toString(), // Change the Key as needed
        Body: file,
        ContentType: file?.type
    };

    return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
}

function FoxUserView() {
    let { form_id } = useParams();
    useEffect(() => {
        dispatch(getCompanyProfile())
    }, []);

    useEffect(() => {
        dispatch(getEptwById(form_id))
    }, [form_id]);

    const { company } = useSelector((state) => state.company);
    const modalBodyStyle = {
        width: '90%',
        margin: '0 auto',
    };
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
    const [rowId, setRowId] = React.useState(null);
    const [fieldId, setFieldId] = React.useState(null);


    const handleCopyClick = async () => {
        // Logic to copy text to clipboard goes here
        await navigator.clipboard.writeText(eptw?.link);
        setSnackbarOpen(true);
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const {
        eptw,
        sections,
        hirarcSections,
        isEptwFillSuccess,
        isEptwFillError,
        isEptwGetLoading
    } = useSelector(state => state.eptw)

    const dispatch = useDispatch();
    const getUserProfileData = async () => {
        await dispatch(getUserProfile());
    }

    useEffect(() => {
        getUserProfileData()
    }, []);

    const convertSectionsToArray = (sections) => {
        const newSections = sections?.map(item => {
            if (item.type === "Checkbox" && item.value === null) {
                return { ...item, value: false }
            }
            return item
        })
        return newSections || []
    }
    useEffect(() => {
        dispatch(setDocumentNumber(eptw?.docuement_number))
        dispatch(setRevisionDate(eptw?.revision_date))
        dispatch(setRevisionNumber(eptw?.revision_number))
        dispatch(setContractorName(eptw?.contractor?.name || ""))
        dispatch(setSubcontractorName(eptw?.sub_contractor || ""))
        dispatch(setsubcontractorRepresentative(eptw?.sub_contractor_representative || ""))
        dispatch(setContractorEmail(eptw?.contractor?.email || ""))
        dispatch(setContractorPhoneNumber(eptw?.contractor?.phone_number || ""))
        dispatch(setWorkType(eptw?.work_type || ""))
        dispatch(setAttributes(eptw?.attributes || []))
        dispatch(setStartDate(eptw?.start_date || ""))
        dispatch(setEndDate(eptw?.end_date || ""))
        dispatch(setDescription(eptw?.work_description || ""))
        dispatch(setCompanyContactNumber(eptw?.contractor?.company_contact_number))
        if (eptw?.contractor?.id_type === "Passport") {
            dispatch(setPassportNumber(eptw?.contractor?.passport?.passport_number))
            dispatch(setLicenseNumber(eptw?.contractor?.passport?.license_number))
            dispatch(setGreenCardNumber(eptw?.contractor?.passport?.saftey_green_card_number))
        } else {
            dispatch(setMykadNumber(eptw?.contractor?.mykad?.mykad_number))
            dispatch(setLicenseNumber(eptw?.contractor?.mykad?.license_number))
            dispatch(setGreenCardNumber(eptw?.contractor?.mykad?.saftey_green_card_number))
        }
        dispatch(setIdType(eptw?.contractor?.id_type))
        if (eptw?.category === "HIRAC") {
            const arr = eptw?.sections?.map((item) => {
                let f = item?.fields.map((ele) => {
                    if (ele.name === "risk_rating") {
                        const severity = item.fields.find((field) => field.name === "severity").value || 1;
                        const likelihood = item.fields.find((field) => field.name === "likelihood").value || 1;
                        return { ...ele, value: severity * likelihood };
                    } else return ele
                })
                return { ...item, fields: f }
            })
            dispatch(setHirarcSections(arr))
        } else {
            dispatch(setSections(convertSectionsToArray(eptw?.sections && eptw?.sections)))
        }
    }, [eptw, form_id])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (isEptwFillSuccess) {
            toast.success('Submitted successfully', {
                toastId: 'SubmittedeSuccess',
            });
            dispatch(reset())
        }
        if (isEptwFillError) {
            toast.error('Something went wrong', {
                toastId: 'SubmittedeError',
            });
            dispatch(reset())
        }
    }, [isEptwFillSuccess, isEptwFillError])

    const handelSections = async (e, ele, index, idx) => {
        const items = await Promise.all(sections?.map(async (item, i) => {
            if (i === index) {
                const f = await Promise.all(item.fields.map(async (l, j) => {
                    if (idx === j) {
                        if (l?.type !== "Checkbox" && l?.type !== "Attachment") {
                            return { ...l, value: e.target.value };
                        } else if (l?.type === "Attachment") {
                            const file = e.target.files[0];
                            const handleFileChange = async () => {
                                try {
                                    const result = await uploadImageToS3(file);
                                    return result.Location;
                                } catch (error) {
                                    console.error('Error uploading image:', error);
                                    throw error; // Rethrow the error if needed
                                }
                            };

                            try {
                                const response = await handleFileChange();
                                return { ...l, value: response };
                            } catch (error) {
                                // Handle the error if needed
                                console.error('Error updating value:', error);
                                throw error; // Rethrow the error if needed
                            }
                        } else {
                            return { ...l, value: !l?.value };
                        }
                    } else return l;
                }));
                return { ...item, fields: f };
            } else return item;
        }));
        dispatch(setSections((items)))
    };
    const date = eptw?.revision_date?.split('T')[0];
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        if (fieldId || rowId) {
            const arr = hirarcSections.map((item, i) => {
                if (i === rowId) {
                    let f = item?.fields.map((ele) => {
                        if (ele.name === "risk_rating") {
                            const severity = item.fields.find((field) => field.name === "severity").value || 1;
                            const likelihood = item.fields.find((field) => field.name === "likelihood").value || 1;
                            return { ...ele, value: severity * likelihood };
                        } else return ele
                    })
                    return { ...item, fields: f }
                } else return item
            })
            dispatch(setHirarcSections(arr))
            setRowId(null)
            setFieldId(null)
        }
    }, [fieldId, rowId])
    const handelHirarcChange = (e, index, idx) => {
        setRowId(index)
        setFieldId(idx)
        const arr = hirarcSections.map((item, i) => {
            if (i === index) {
                let f = item?.fields.map((ele, j) => {
                    if (j === idx) {
                        return { ...ele, value: e.target.value };
                    } else return ele
                })
                return { ...item, fields: f }
            } else return item
        })
        dispatch(setHirarcSections(arr))
    }

    const handelChangeOnMain = (e) => {
        const arr = hirarcSections.map((item) => {
            if (item.title === "main") {
                const f = item?.fields.map((ele) => {
                    if (e.target.name === ele.name) {
                        return { ...ele, value: e.target.value }
                    } else return ele
                })
                return { ...item, fields: f }
            } else return item
        })
        dispatch(setHirarcSections(arr))
    }

    const addNewRowHandeler = () => {
        const noOfNewRow = hirarcSections.length
        const newRow = {
            "title": noOfNewRow,
            "fields": [
                { "name": "sequence_of_activity", "type": "String", "value": null },
                { "name": "potential_hazard", "type": "String", "value": null },
                { "name": "potential_imacts", "type": "String", "value": null },
                { "name": "existing_controls", "type": "String", "value": null },
                { "name": "severity", "type": "Number", "value": 1 },
                { "name": "likelihood", "type": "Number", "value": 1 },
                { "name": "risk_rating", "type": "Number", "value": 1 },
                { "name": "recommended_control_measures", "type": "String", "value": null },
            ]
        }
        dispatch(setHirarcSections([...hirarcSections, newRow]))
    }
    const removeRowHandeler = () => {
        const arr = [...hirarcSections]
        arr.pop()
        dispatch(setHirarcSections(arr))
    }

    const handelSubmit = async (e) => {
        e.preventDefault()
        if (eptw?.category === "HIRAC") {
            await dispatch(updateFillEptw({ ...eptw, sections: hirarcSections }))
        } else {
            await dispatch(updateFillEptw({ ...eptw, sections }))
        }
    }

    if (isEptwGetLoading) {
        return <Loader />
    }
    return (
        <>
            <>
                {eptw?.category !== "HIRAC" ? <form
                >
                    <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
                        <div id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                            <div className="col-lg-12 col-sm-12 p-5">
                                <div className="row w-100">
                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Document Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                            placeholder="Document Number"
                                            value={eptw?.document_number || ""}
                                        />
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Permit Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                            placeholder="Document Number"
                                            value={eptw?.id || ""}
                                        />
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Revision Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                            placeholder="Revision Number"
                                            value={eptw?.revision_number || ""}
                                        />
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Revision Date<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='date' type="date" className="form-control mb-2"
                                            placeholder="Revision Date"
                                            value={date || "N/A"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 pt-3">
                                <div className="row">

                                </div>
                            </div>
                            {sections?.length > 0 && <div className="col-lg-12 col-sm-12 pl-5 pr-5">
                                <div>
                                    {sections.map((item, index) => (
                                        <div className={classes.templateSection} key={index}>
                                            <h3 className={classes.sectionTitle}>{item.title}</h3>
                                            <ul className={classes.sectionFeilds}>
                                                {item?.fields?.map((ele, idx) => {
                                                    const type = ele?.type
                                                    console.log(ele);
                                                    return type === "String" || type === "Date" ? <div className="col-lg-12 col-sm-12">
                                                        <label className="font-weight-normal">{ele.name}</label>{ele?.isRequired === true && <h6 style={{ marginTop: '30px', textAlign: 'center' }} className="font-danger fs-6 m-0">*Required</h6>}
                                                        <input
                                                            type={type === "String" ? "text" : "datetime-local"}
                                                            className="form-control mb-2"
                                                            value={ele.value}
                                                            onChange={(e) => handelSections(e, ele, index, idx)}
                                                        />
                                                    </div> : type === "Checkbox" ? <div className="col-lg-12 col-sm-12">
                                                        <div className={classes.templateCheckbox} style={{ mr: 0 }}>
                                                            <input type="Checkbox"
                                                                checked={ele.value}
                                                                onChange={(e) => handelSections(e, ele, index, idx)} />
                                                            <label className="font-weight-normal">{ele.name}</label>
                                                        </div>
                                                    </div> : type == 'Description' ? <div className="col-lg-12 col-sm-12"><h6>{ele.name}</h6></div> :
                                                        type === "Attachment" ? <div className="col-lg-12 col-sm-12">
                                                            <label className="font-weight-normal">{ele.name}</label>{ele?.isRequired === true && <h6 style={{ marginTop: '30px', textAlign: 'center' }} className="font-danger fs-6 m-0">*Required</h6>}
                                                            <input
                                                                type="file"
                                                                accept=".pdf, image/*"
                                                                className="form-control mb-2"
                                                                onChange={(e) => handelSections(e, ele, index, idx)}
                                                            />
                                                            {ele.value && <div style={{ width: "80% ", margin: "auto" }}> <a href={ele.value} target="_blank">Link of Attachment</a> </div>}
                                                        </div> :
                                                            <div className="col-lg-12 col-sm-12 pt-3">
                                                                <label className="font-weight-normal">{ele.name}{ele.isRequired === true && <h6 className="font-danger fs-6 m-0">*</h6>}</label>
                                                                <Signature required={ele} index={index} idx={idx} sections={sections} value={ele.value} />
                                                            </div>
                                                })}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </form> : <div style={{ padding: "30px" }}>
                    <table className={classes.table1}>
                        <thead>
                            <tr>
                                <td>
                                    <img
                                        src={company?.logo || logo}
                                        alt=''
                                        className='brand-image img-company'
                                        id='result_company_base'
                                        height='100'
                                        width='100'
                                    />
                                </td>
                                <td >NORTHERN CORRIDOR IMPLEMENTATION AUTHORITY</td>
                            </tr>
                        </thead>
                    </table>
                    <table className={classes.table2}>
                        <thead>
                            <tr>
                                <td >Hazard Identification Risk Assessment and Risk Control</td>
                            </tr>
                        </thead>
                    </table>
                    {hirarcSections.length > 0 && <> <table className={classes.table3}>
                        <tbody>
                            <tr>
                                <td> Contractor Company Name <br />
                                    <input type="text"
                                        value={hirarcSections[0].fields[0].value || ""}
                                        name={hirarcSections[0].fields[0].name}
                                        onChange={handelChangeOnMain}
                                    />
                                </td>
                                <td >Project Title <br />
                                    <input type="text"
                                        value={hirarcSections[0].fields[1].value || ""}
                                        name={hirarcSections[0].fields[1].name}
                                        onChange={handelChangeOnMain}
                                    /></td>
                                <td >Activity Assessed    <br />
                                    <input type="text"
                                        value={hirarcSections[0].fields[2].value || ""}
                                        name={hirarcSections[0].fields[2].name}
                                        onChange={handelChangeOnMain} /></td>
                                <td >PTW No   <br />
                                    <input type="text"
                                        value={hirarcSections[0].fields[3]?.value || ""}
                                        name={hirarcSections[0].fields[3]?.name}
                                        onChange={handelChangeOnMain} />
                                </td>
                            </tr>
                            <tr>
                                <td >Conducted by  <br />
                                    <input type="text"
                                        value={hirarcSections[0].fields[4]?.value || ""}
                                        name={hirarcSections[0].fields[4]?.name}
                                        onChange={handelChangeOnMain} /></td>
                                <td>Date <br />
                                    <input type="datetime-local"
                                        value={hirarcSections[0].fields[5]?.value || ""}
                                        name={hirarcSections[0].fields[5]?.name}
                                        onChange={handelChangeOnMain}
                                    /></td>
                                <td >Approved by:  <br />
                                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                        {eptw?.approvals?.filter(item =>item.status === "Approved")?.map((item, index) => (
                                            <Avatar key={index} {...stringAvatar(item?.approver?.first_name || "", item?.approver?.last_name || "", item?.approver?.image)} title={item?.approver?.first_name || "contractor"} alt={item?.approver?.first_name || "contractor"} />))}
                                    </div> */}
                                    {eptw?.approvals?.filter(item => item.status === "Approved")?.map((item) => (
                                        eptw?.approvals?.length > 1 ?
                                            <p>{item?.approver?.first_name} {item?.approver?.last_name}</p> :
                                            <input type="text" disabled value={`${item?.approver?.first_name} ${item?.approver?.last_name}`} />
                                    ))}
                                </td>
                                <td>Date <br />
                                    <input type="datetime-local"
                                        value={moment(eptw?.approved_date).format('YYYY-MM-DD HH:mm') || ""}
                                        disabled
                                    /></td>
                            </tr>
                        </tbody>
                    </table>

                        <table className={classes.table4}>
                            <thead>
                                <tr>
                                    <td colSpan="4"> Hazard Identification  </td>
                                    <td colSpan="4">Risk Assessment </td>
                                    <td colSpan="1">Risk Control     </td>
                                </tr>
                                <tr>
                                    <td >  No.   </td>
                                    <td >Sequence of Activity/Task  </td>
                                    <td >Potential Hazard  </td>
                                    <td >Potential impact or Effects     </td>
                                    <td >Existing Control Measures      </td>
                                    <td >Severity      </td>
                                    <td >Likelihood      </td>
                                    <td >Risk Ranking       </td>
                                    <td >Recommended Control Measures </td>
                                </tr>
                            </thead>
                            <tbody>
                                {hirarcSections.map((item, index) => {
                                    if (item.title !== "main") {
                                        return <tr>
                                            <td>{item?.title}</td>
                                            {item?.fields?.map((ele, idx) => {
                                                if (ele.type === "String") {
                                                    return <td ><input type="text"
                                                        name={ele.name}
                                                        value={ele.value || ""}
                                                        onChange={(e) => handelHirarcChange(e, index, idx)} />
                                                    </td>
                                                } else if (ele.name === "severity") {
                                                    return (<td >
                                                        <select name={ele.name}
                                                            style={{ fontSize: "16px", fontWeight: "normal", color: "black" }}
                                                            value={ele.value || ""}
                                                            onChange={(e) => handelHirarcChange(e, index, idx)}>
                                                            <option value={1}>(1) Negligible (Minor abrasions, bruises, cuts, first aid type injury)    </option>
                                                            <option value={2}>(2) Minor (Disabling but not permanent injury)      </option>
                                                            <option value={3}>(3) Serious (Non-fatal injury, permanent disability)   </option>
                                                            <option value={4}>(4) Fatal (Approximately one single fatality major property damage if hazard is realized) .  </option>
                                                            <option value={5}>(5) Catastrophic (Numerous fatalities, irrecoverable property damage and productivity)      </option>
                                                        </select>
                                                    </td>
                                                    )
                                                } else if (ele.name === "likelihood") {
                                                    return (<td>
                                                        <select name={ele.name}
                                                            style={{ fontSize: "16px", fontWeight: "normal", color: "black" }}
                                                            value={ele.value || ""}
                                                            onChange={(e) => handelHirarcChange(e, index, idx)}>
                                                            <option value={1}>(1) Inconceivable (Is practically impossible and has never occurred)  </option>
                                                            <option value={2}>(2) Remote (Has not been known to occur after many years)     </option>
                                                            <option value={3}>(3) Conceivable (Might be occurring at some time in future)   </option>
                                                            <option value={4}>(4) Possible (Has a good chance of occurring and is not unusual)  </option>
                                                            <option value={5}>(5) Most likely (The most likely result of the hazard/event being realized)  </option>
                                                        </select>
                                                    </td>
                                                    )
                                                } else if (ele.name === "risk_rating") {
                                                    return (<td
                                                        style={{
                                                            background: ele?.value <= 4 ? "green"
                                                                : (ele?.value >= 5 && ele?.value <= 14) ? "yellow" : 'red'
                                                        }}>
                                                        {ele.value}
                                                    </td>
                                                    )
                                                }
                                            })}
                                        </tr>
                                    }
                                })}
                            </tbody>
                        </table>
                    </>
                    }
                    {eptw?.status !== "Approved" && eptw?.status !== "Closed" && <>
                        <button className='secondary-btn btn mt-3' onClick={addNewRowHandeler}>Add New Row</button>
                        <button className='secondary-btn btn mt-3 ml-2' disabled={hirarcSections?.length === 1} onClick={removeRowHandeler}>Remove Last Row</button>
                    </>
                    }
                    <div>
                    </div>
                </div>
                }
            </>

            {eptw?.category === "PTW" && <div style={{ paddingBottom: '1.25rem', display: 'none' }}>
                <div className="card-body" ref={componentRef}>
                    <PrintPdf sections={sections} eptw={eptw} />
                </div>
            </div>
            }
            <div style={{ display: "flex", justifyContent: "space-between" }} className='pl-5 pr-5 pb-5'>
                <div>
                    <Snackbar
                        open={isSnackbarOpen}
                        autoHideDuration={3000} // Adjust the duration as needed
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert severity="error" style={{
                            color: "#f63854",
                            // color: 'white', // Text color
                        }} onClose={handleCloseSnackbar}>
                            Text copied to clipboard!
                        </Alert>
                    </Snackbar>
                    {eptw?.category === "PTW" && <button id='srvc-btn' type='button' className="btn primary-btn mr-2" onClick={handlePrint}> Print PDF </button>}
                    <button id='srvc-btn' type='button' className="btn primary-btn" onClick={handleCopyClick}> Copy Link </button>
                </div>
                <div>
                    {eptw?.status !== "Approved" && eptw?.status !== "Closed" && <button className="btn primary-btn mr-2"
                        onClick={handelSubmit}
                        disabled={(user?.role !== "Administrator") && (user?.role !== "Manager")}
                    >
                        Submit
                    </button>}

                </div>
            </div>
        </>

    )
}

export default FoxUserView